import React, {Component} from "react";
import LoaderButton from "../components/LoaderButton";

export default class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            reqCode: "",
        };
    }

    validateForm() {
        return this.state.reqCode.length > 0;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value ? event.target.value.toUpperCase() : ""
        });
    };

    handleSubmit = async event => {
        event.preventDefault();
        try {
            this.props.history.push("/sjekk/" + this.state.reqCode);
        } catch (e) {
            alert(e.message);
        }
    };

    render() {
        return (
            <div className="row justify-content-md-center mt-2">
                <div className="col-md-8 col-lg-6">
                    <div className="content shadow">
                        <div className="row justify-content-md-center">
                            <div className="col-lg-10">
                                <div className="Login">
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-group">
                                            <label htmlFor="reqCode">Rekvisisjonskode:</label>
                                            <div className="input-group mb-2">
                                                <input type="text" className="form-control form-control-lg" id="reqCode"
                                                       value={this.state.reqCode}
                                                       onChange={this.handleChange} autoFocus={true}/>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="input-group mb-2">
                                                <LoaderButton
                                                    block
                                                    className="btn-block btn-lg btn-bluecolor"
                                                    disabled={!this.validateForm()}
                                                    type="submit"
                                                    text="Sjekk"
                                                    loadingText="Vennligst vent…"
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
