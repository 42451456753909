import React, {Component} from "react";
import {Link, withRouter} from "react-router-dom";
import Routes from "./Routes";
import {Auth} from "aws-amplify";
import "./App.css";

class App extends Component {
    constructor(props) {
        super(props);
    }

    renderVersionBar() {
        if (process.env.REACT_APP_STAGE === 'test') {
            return (
                <div className="alert alert-warning mb-0 text-center" role="alert">
                    TEST - BUTIKK
                </div>
            )
        }
    }

    renderNavbar() {
        return (
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <a className="navbar-brand mr-auto" href="/">
                    <img src="/logo_farger.svg" height="30" alt="NUH! Logo"/>
                    <span className={"ml-2"}>Butikk</span>
                </a>
            </nav>
        );
    }

    render() {
        return (
            <div>
                <div className="container-fluid">
                    {this.renderVersionBar()}
                    {this.renderNavbar()}
                </div>
                <div className="container-fluid mt-5">
                    <div className="mr-3 ml-3">
                        <Routes childProps={{}}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(App);

