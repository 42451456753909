import React, {Component} from "react";

export default class EmployeeDetail extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const employee = this.props.employee;
        return (
            <div>
                <img src={"/user-" + employee.userPhone + ".png"} className="card-img img-thumbnail rounded"
                     alt="Employee picture" style={{maxWidth: '400px'}}/>
            </div>
        );
    }
}
