import React, {Component} from "react";
import {API} from "aws-amplify";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import EmployeeDetail from "./EmployeeDetail";
import config from "../config";

export default class ItemCheck extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            item: null,
            refValue: '',
            user: null
        };
    }

    async componentDidMount() {
        try {
            const item = await this.item();
            // console.log(item);
            const user = item ? await this.user(item.userId) : null;
            const refValue = item ? item.reqPrefix + '/' + item.speedyId + '/' + this.props.match.params.reqCode : '';
            // console.log(user);
            this.setState({item: item, user: user, refValue: refValue});
        } catch (e) {
            alert(e);
        }

        this.setState({isLoading: false});
    }

    item() {
        return API.get("store", `/check/${this.props.match.params.reqCode}`);
    }

    user(userId) {
        return API.get("store", `/user/${userId}`);
    }

    renderItemInfo() {
        return (
            <div className={"card"}>
                <h5 className="card-header"><span
                    className="badge badge-secondary">{this.props.match.params.reqCode}</span></h5>
                <div className="row no-gutters">
                    <div className="col-md-8">
                        <div className="card-body">
                            <p className="card-text">Noe fornuftig informasjon om kjøpet?</p>
                            <p className="card-text">Flere linjer..</p>
                            <p className="card-text">
                                <small className="text-muted">Sist oppdatert: 3 minutter siden.</small>
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4 text-right">
                        <span className="fa-stack fa-2x mt-2">
                          <i className="fas fa-circle fa-stack-2x"
                             style={{color: this.state.item.status ? "Forestgreen" : "Tomato"}}/>
                            {this.state.item.status && <i className={"fad fa-thumbs-up fa-stack-1x fa-inverse"}/>}
                            {!this.state.item.status && <i className={"fad fa-thumbs-down fa-stack-1x fa-inverse"}/>}
                        </span>
                    </div>
                </div>
            </div>
        );
    }

    renderCopyButton() {
        if (this.state.copied) {
            return (
                <button className={"btn btn-success"}><span className={"fad fa-check mr-2"}/> Kopiert!</button>
            )
        }
        return (
            <button className={"btn btn-outline-info"}><span className={"fad fa-copy"}/> Kopier</button>
        );
    }

    renderRekvirentInfo() {
        let companyKey = 'vke';
        if (this.state.item.companyTitle === 'Sundquist') {
            companyKey = 'sundquist';
        }
        return (
            <div>
                <h4>Rekvirent:</h4>
                <p>{this.state.item.companyTitle}</p>
                <h5 className={"mt-3"}><span className="text-danger">EHF (ønskelig):</span></h5>
                <p className={"mb-0"}>Org.nr: {config[companyKey].companyOrgNr}</p>
                <p>E-post: {config[companyKey].companyEmail}</p>
                <h5 className={"mt-3"}><span className="text-danger">Fakturaadresse:</span></h5>
                <p className={"mb-0"}>{config[companyKey].companyName}</p>
                <p className={"mb-0"}>{config[companyKey].companyAddress}</p>
                <p>{config[companyKey].companyPostalCode} {config[companyKey].companyPostalCity}</p>
            </div>
        )
    }

    renderItem() {
        if (!this.state.isLoading) {
            if (this.state.item) {
                return (
                    <div className="row mt-2">
                        <div className="col">
                            <div className="row mt-2 mb-3">
                                <div className="col">
                                    <h1>Referanse:</h1>
                                    <h1>
                                        <span className="badge badge-info mr-2">{this.state.refValue}</span>
                                        <CopyToClipboard text={this.state.refValue}
                                                         onCopy={() => this.setState({copied: true})}>
                                            {this.renderCopyButton()}
                                        </CopyToClipboard>
                                    </h1>
                                </div>
                                <div className="col">
                                    <div className="text-right">
                                        <span className="fa-stack fa-2x mt-2">
                                          <i className="fas fa-circle fa-stack-2x"
                                             style={{color: "Forestgreen"}}/>
                                          <i className={"fad fa-thumbs-up fa-stack-1x fa-inverse"}/>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2 mb-3">
                                <div className="col">
                                    <h4>Rekvisisjon: {this.props.match.params.reqCode}</h4>
                                </div>
                            </div>
                            <div className="row mt-2 mb-3">
                                <div className="col">
                                    {this.renderRekvirentInfo()}
                                    <h4>Kjøpsbeskrivelse:</h4>
                                    <p>{this.state.item.content}</p>
                                </div>
                                <div className="col">
                                    <EmployeeDetail employee={this.state.user}/>
                                </div>
                            </div>
                            <div className="mt-2">
                                <a href={"/"} className={"btn btn-primary btn-bluecolor"}>Avslutt</a>
                            </div>
                        </div>
                    </div>
                )
            }
            return (
                <div className="row mt-2 justify-content-md-center">
                    <div className="col">
                        <h4>Beklager, men finner ingen rekvisisjon med oppgitt kode
                            [{this.props.match.params.reqCode}].</h4>
                        <p>Vennligst prøv igjen med en annen kode.</p>
                        <div className="text-right">
                            <a href={"/"} className={"btn btn-primary btn-bluecolor"}>Tilbake</a>
                        </div>
                    </div>
                </div>
            )
        }
    }

    renderLoading() {
        if (this.state.isLoading) {
            return (
                <div className="row mt-2 justify-content-md-center">
                    <div className="col text-center">
                        <i className="fas fa-spinner fa-spin"/>
                    </div>
                </div>
            )
        }
    }

    render() {
        return (
            <div className="row mt-2 justify-content-md-center">
                <div className="col-md-8">
                    <div className="content shadow">
                        {this.renderLoading()}
                        {this.renderItem()}
                    </div>
                </div>
            </div>
        );
    }
}