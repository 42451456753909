import React from "react";
import { Route, Switch } from "react-router-dom";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import Home from "./containers/Home";
import ItemCheck from "./containers/ItemCheck";
import NotFound from "./containers/NotFound";

export default ({ childProps }) =>
    <Switch>
        <UnauthenticatedRoute path="/" exact component={Home} props={childProps} />
        <UnauthenticatedRoute path="/sjekk/:reqCode" exact component={ItemCheck} props={childProps} />
        {/*<AuthenticatedRoute path="/items/:id" exact component={Items} props={childProps} />*/}
        { /* Finally, catch all unmatched routes */ }
        <Route component={NotFound} />
    </Switch>;