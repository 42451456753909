const test = {
    apiGateway: {
        REGION: "eu-central-1",
        URL: "https://iyfnxh1jqd.execute-api.eu-central-1.amazonaws.com/test"
    },
    vke: {
        companyOrgNr: '985519250',
        companyEmail: 'fakturamottak@varanger-kraft.no',
        companyName: 'Varanger Kraftentreprenør AS',
        companyAddress: 'Postboks 2742',
        companyPostalCode: '7439',
        companyPostalCity: 'Trondheim',
    },
    sundquist: {
        companyOrgNr: '916425023',
        companyEmail: 'faktura@sundquist.no',
        companyName: 'AS Oscar Sundquist',
        companyAddress: 'Langfjordveien 62',
        companyPostalCode: '9910',
        companyPostalCity: 'Bjørnevatn',
    }
};

const prod = {
    apiGateway: {
        REGION: "eu-central-1",
        URL: "https://z2jp6ksxj8.execute-api.eu-central-1.amazonaws.com/prod"
    },
    vke: {
        companyOrgNr: '985519250',
        companyEmail: 'fakturamottak@varanger-kraft.no',
        companyName: 'Varanger Kraftentreprenør AS',
        companyAddress: 'Postboks 2742',
        companyPostalCode: '7439',
        companyPostalCity: 'Trondheim',
    },
    sundquist: {
        companyOrgNr: '916425023',
        companyEmail: 'faktura@sundquist.no',
        companyName: 'AS Oscar Sundquist',
        companyAddress: 'Langfjordveien 62',
        companyPostalCode: '9910',
        companyPostalCity: 'Bjørnevatn',
    }
};

const config = process.env.REACT_APP_STAGE === 'production' ? prod : test;

export default {
    // Add common config values here
    ...config
};